class MobileMenu {
	constructor() {
		this.menuIcon = document.querySelector('.site-header__menu-icon');
		this.menuContent = document.querySelector('.site-header__menu-content');
		this.siteHeader = document.querySelector('.site-header');
		this.bodyName = document.querySelector('body');
		/* Expanded sub menu for mobile view */
		/* this.menu_item_expand = document.querySelector('li.menu-item-has-children span.no_page_link');
		this.menu_item_expand_submenu = document.querySelector('li.menu-item-has-children ul.sub-menu'); */
		this.events();
	}

	events() {
		this.menuIcon.addEventListener('click', () => this.toggleTheMenu());
		/* this.menu_item_expand.addEventListener('click', () => this.toggleMenuItem()); */

	}

	toggleTheMenu() {
		this.menuContent.classList.toggle('site-header__menu-content--is-visible');
		this.siteHeader.classList.toggle('site-header--is-expanded');
		this.menuIcon.classList.toggle('site-header__menu-icon--close-x');
		this.bodyName.classList.toggle('hidescroll');
	}
	/* toggleMenuItem() {
		//this.menu_item_expand.classList.toggle('expanded');
		this.menu_item_expand_submenu.classList.toggle('expanded');
	} */
}

export default MobileMenu;
