import '../css/styles.css';

/* Main Website JS */
// Modules
import MobileMenu from './modules/MobileMenu';
import Testimonials from './modules/testimonials';
import StickyHeader from './modules/StickyHeader';
import RevealOnScroll from './modules/RevealOnScroll';
import ExpanderMenu from './modules/ExpanderMenu';

// const homepage = document.querySelector('.home');
// if (homepage !== null) {
// 	new RevealOnScroll(document.querySelectorAll('.latest_news'), 80);
// 	new RevealOnScroll(document.querySelectorAll('.information_blocks'), 80);
// 	new RevealOnScroll(document.querySelectorAll('#stayUpdated'), 80);
// 	new RevealOnScroll(document.querySelectorAll('#Subscribe'), 80);
// };

// Global Functionality
new StickyHeader();
let mobileMenu = new MobileMenu();

/*
 To include FONTAWESOME in the project
 1. include the icon library required
 2. add each one from their respective lib
 3. then add their names to the lib call
 4. add usual <i> tag call in php or html element
*/
import {
	library,
	dom
} from '@fortawesome/fontawesome-svg-core'
// Import solid weight icons
import {
	faUser as fasUser,
	faCheck,
	faMobileAlt,
	faCalendarAlt as fasCalendar,
	faUserCircle as fasUserCircle,
	faWifi,
	faFolder,
	faAngleDown,
	faAngleUp,
	faChartLine,
	faTrafficLight,
	faUsers,
	faBriefcase,
	faBuilding,
	faHome,
	faConciergeBell,
	faTools,
	faPenFancy,
	faPhoneAlt,
	faBullhorn,
	faMapMarkerAlt,
	faFileAlt,
	faWrench,
	faClock,
	faFilePdf,
	faSearch,
	faLink
} from '@fortawesome/free-solid-svg-icons'
// Import regular weight icons
import {
	faUser as farUser,
	faUserCircle as farUserCircle,
	faHeart as farHeart,
	faComment,
	faCopyright,
	faEnvelope
} from '@fortawesome/free-regular-svg-icons'
// Import brand icons
import {
	faTwitter as fabTwitter,
	faFacebookF as fabFacebookF,
	faYoutube as fabYoutube,
	faPinterestP as fabPinterestP,
	faLinkedinIn as fabLinkedinIn,
	faInstagram as fabInstagram
} from '@fortawesome/free-brands-svg-icons';
// Add icons to library
// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
library.add(
	// Solid
	fasUser,
	faCheck,
	faMobileAlt,
	faBriefcase,
	faBuilding,
	faHome,
	fasCalendar,
	faFolder,
	fasUserCircle,
	farHeart,
	faWifi,
	faLink,
	faAngleDown,
	faAngleUp,
	faChartLine,
	faTrafficLight,
	faUsers,
	faConciergeBell,
	faTools,
	faPenFancy,
	faPhoneAlt,
	faBullhorn,
	faMapMarkerAlt,
	faFileAlt,
	faWrench,
	faClock,
	faFilePdf,
	faSearch,
	// Regular
	farUser,
	faCheck,
	farUserCircle,
	faComment,
	faCopyright,
	faEnvelope,
	// Brands to use icons with - ie. fa-Linked-in, need to remove dashes adn replace with capitals
	fabTwitter,
	fabFacebookF,
	fabYoutube,
	fabPinterestP,
	fabLinkedinIn,
	fabInstagram
);
// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();

/*
 * To add jquery to any page etc
 * this looks for a class name in the boy tag to see if it should load jQuery 
 */
/* const use_jQuery = document.querySelector('.use_jQuery');
if (use_jQuery !== null) {

} */
//import "jquery";

// back-to-top
// Set a variable for our button element.
const ToTopButton = document.getElementById('js-top');

// Let's set up a function that shows our scroll-to-top button if we scroll beyond the height of the initial window.
const scrollFunc = () => {
	// Get the current scroll value
	const windowheight = window.scrollY;

	// If the scroll value is greater than the window height, let's add a class to the scroll-to-top button to show it!
	if (windowheight > 500) {
		ToTopButton.className = 'back_to_top show';
	} else {
		ToTopButton.className = 'back_to_top hide';
	}
};

window.addEventListener('scroll', scrollFunc);

// When the button is clicked, run our ScrolltoTop function above!
ToTopButton.onclick = function (e) {
	e.preventDefault();
	document.body.scrollTop = 0;
	document.documentElement.scrollTop = 0;
};

//show the search bar in menu
const search_btn = document.querySelector('.search-toggle');
const search_bar_input = document.querySelector('.search-box-wrapper');

search_btn.addEventListener('click', function () {
	this.classList.toggle('active');
	search_bar_input.classList.toggle('active');
});
